<template>
    <div class="page" id="reorganize">
        <el-form class="query-form searchForm" size="small"
                 @keyup.enter.native="getDataList(1,1)"
                 ref="inputForm" :model="inputForm" label-width="100px">
            <el-form-item label="出库原因" prop="reason">
                <el-select
                        v-model="inputForm.reason"
                        clearable
                        placeholder="请选择出库原因"
                        style="width: 100%"
                >
                    <el-option
                            v-for="item in this.$dictUtils.getDictList('outbound_reason').filter(item=>{return item.value != 0})"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <!--            <el-form-item label="出库申请人" prop="applicant">-->
            <!--                <el-select-->
            <!--                        style="width: 100%;"-->
            <!--                        v-model="inputForm.applicant"-->
            <!--                        filterable-->
            <!--                        remote-->
            <!--                        reserve-keyword-->
            <!--                        placeholder="请选择出库申请人"-->
            <!--                        @focus="remoteMethod"-->
            <!--                        :remote-method="remoteMethod"-->
            <!--                        :loading="applicantLoading">-->
            <!--                    <el-option-->
            <!--                            v-for="item in applicantList"-->
            <!--                            :key="item.id"-->
            <!--                            :label="item.name"-->
            <!--                            :value="item.id">-->
            <!--                    </el-option>-->
            <!--                </el-select>-->
            <!--            </el-form-item>-->
            <el-form-item label="出库状态" prop="storeState">
                <el-select
                        v-model="inputForm.storeState"
                        clearable
                        placeholder="请选择出库状态"
                        style="width: 100%"
                >
                    <el-option
                            v-for="item in storeStateList"
                            :key="item.state"
                            :label="item.stateName"
                            :value="item.state"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="审批状态" prop="reviewState">
                <el-select
                        v-model="inputForm.reviewState"
                        clearable
                        placeholder="请选择审批状态"
                        style="width: 100%"
                >
                    <el-option
                            v-for="item in reviewStateList"
                            :key="item.state"
                            :label="item.stateName"
                            :value="item.state"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="具体原因" prop="specificReasons">
                <el-input v-model.trim="inputForm.specificReasons"
                          placeholder="请输入具体原因(限50字)"
                          maxlength="50"
                          clearable>
                </el-input>
            </el-form-item>
            <el-form-item label="出库时间" prop="outDate" class="dateBox2">
                <el-date-picker
                    v-model="inputForm.outDate"
                    type="daterange"
                    clearable
                    style="width: 100%;"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="预入库时间" prop="preDate" class="dateBox2">
                <el-date-picker
                    v-model="inputForm.preDate"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    clearable
                    style="width: 100%;"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item class="dateBox3" label-width="0">
                <div class="flex_b_c">
                    <div>
                        <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询</el-button>
                        <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
                    </div>
                    <el-button type="primary" size="small" icon='el-icon-plus'
                               v-show="hasPermissionButton(`collection:business:${typePage}:add`)"
                               @click="addData(0)">新增出库</el-button>
                </div>
            </el-form-item>
        </el-form>

        <div class="bg-white">
            <el-table
                    :data="dataList"
                    size="small"
                    v-loading="loading"
                    ref="multipleTable"
                    height="calc(100vh - 355px)"
                    class="table"
                    :row-key="'id'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                <el-table-column prop="reason" label="出库原因" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("outbound_reason", scope.row.reason, '-') }}
                    </template>
                </el-table-column>
                <el-table-column prop="specificReasons" label="具体原因" show-overflow-tooltip></el-table-column>
                <el-table-column prop="collectionNum" label="藏品种数" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="storeState" label="出库状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ getlistSelet(scope.row.storeState, storeStateList, 'state', 'stateName') }}
                    </template>
                </el-table-column>
                <el-table-column prop="reviewState" label="审核状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{
                        scope.row.reviewState == '0' ? '审核中' : scope.row.reviewState == '1' ? '审核驳回' : '审核通过'
                        }}
                    </template>
                </el-table-column>

                <el-table-column prop="applicationState" label="申请状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ getlistSelet(scope.row.applicationState, applicationStateList, 'value', 'name') }}
                    </template>
                </el-table-column>
                <el-table-column prop="submitDate" label="提交时间" width="100px">
                    <template slot-scope="scope">
                        {{ scope.row.submitDate | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="operationDate" label="出库时间" width="100px">
                    <template slot-scope="scope">
                        {{ scope.row.operationDate | formatDate('YYYY-MM-DD') }}
                    </template>
                </el-table-column>
                <el-table-column prop="preDate" label="预入库时间" width="100px"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-if="scope.row.preDate">{{ scope.row.preDate | formatDate('YYYY-MM-DD') }}</div>
                        <div v-else></div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text"
                                   @click="addData(1, scope.row,scope.$index)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
                            详情
                        </el-button>
                        <el-button size="mini" type="text"
                                   @click="addData(2,scope.row,scope.$index)"
                                   v-if="scope.row.reviewState == 1"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:resubmit`)">
                            重新提交
                        </el-button>
                        <el-button size="mini" type="text"
                                   @click="record(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:record`)">
                            审批记录
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text_center">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>
        <record ref="record"></record>
    </div>
</template>

<script>
import record from "@/views/modules/collection/accounts/module/record.vue";

export default {
    name: "myOutbound",
    components: {record},
    props: {
        typePage: {
            type: String,
            default: 'myOutbound',
        },
    },
    data() {
        return {
            inputForm: {
                reason: '',
                applicant: '',

                outDate: [],
                outBountStartDate: '',
                outBountEndDate: '',

                preDate: [],
                preStartDate: '',
                preEndDate: '',

                storeState: '',
                reviewState: '',
                specificReasons: '',
            },
            applicantLoading: false,
            applicantList: [],
            storeStateList: [],
            reviewStateList: [],

            loading: false,
            dataList: [],
            dataListSelect: [],
            pageNo: 1,
            pageNo2: 0,
            pageSize: 10,
            total: 0,
            searchRecord: {},
            applicationStateList: [
                {
                    name: '申请中',
                    value: '0',
                },
                {
                    name: '申请驳回',
                    value: '1',
                },
                {
                    name: '申请通过',
                    value: '2',
                },
                {
                    name: '已结束',
                    value: '3',
                },
            ],
        }
    },

    mounted() {
        let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
        if (listSearch) {
            this.inputForm = listSearch.inputForm
            this.pageNo2 = listSearch.current
            this.pageSize = listSearch.pageSize
        }
        this.getAllState()
        this.getDataList()
    },

    methods: {
        getDataList(type, dividePage) {
            if (type == 1) {
                this.pageNo = 1
            }
            if (dividePage == 1) {
                this.$refs.multipleTable.clearSelection()
            }
            this.searchRecord = {
                ...this.inputForm,
                current: this.pageNo2 ? this.pageNo2 : this.pageNo,
                orderType: 1,
                size: this.pageSize,
            }
            if (this.searchRecord.outDate && this.searchRecord.outDate.length == 2) {
                this.searchRecord.outBountStartDate = this.searchRecord.outDate[0]
                this.searchRecord.outBountEndDate = this.searchRecord.outDate[1]
            } else {
                this.searchRecord.outBountStartDate = ''
                this.searchRecord.outBountEndDate = ''
            }
            this.$delete(this.searchRecord, 'outDate')
            if (this.searchRecord.preDate && this.searchRecord.preDate.length == 2) {
                this.searchRecord.preStartDate = this.searchRecord.preDate[0]
                this.searchRecord.preEndDate = this.searchRecord.preDate[1]
            } else {
                this.searchRecord.preStartDate = ''
                this.searchRecord.preEndDate = ''
            }
            this.$delete(this.searchRecord, 'preDate')

            this.loading = true
            this.$axios(this.api.collection.listOrder, this.searchRecord, 'post').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                    this.total = parseInt(res.data.total)
                    if (this.dataList.length == 0 && this.pageNo > 1) {
                        this.pageNo--
                        this.getDataList()
                    }
                    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
                    if (listSearch) {
                        this.pageNo = listSearch.pageNo
                        this.pageNo2 = 0
                        sessionStorage.removeItem('listSearch')
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })

        },

        //重置
        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1)
        },

        // 0新增 1详情 2修改
        addData(num, row, index) {
            if (num != 0) {
                let listSearch = {
                    inputForm: this.inputForm,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                }
                sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
            }
            if (num == 0) {
                this.$router.push({
                    path: '/collection/business/outBound/addOutbound',
                    query: {butType: num},
                })
            }
            if (num == 1) {
                this.searchRecord.current = (this.pageNo - 1) * this.pageSize + index + 1
                this.searchRecord.size = 1
                sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
                this.$router.push({
                    path: '/collection/business/outBound/addOutbound',
                    query: {butType: num, id: row.id}
                })
            }
            if (num == 2 || num == 4) {
                this.$router.push({
                    path: '/collection/business/outBound/addOutbound',
                    query: {butType: num, id: row.id}
                })
            }
        },

        //审核记录
        record(row) {
            this.$refs.record.init(row.id,'',16)
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.pageNo = val;
            this.getDataList('', '');
        },

        //列表展示
        getlistSelet(id, data, idName, name) {
            let seleRow = data.filter(item => {
                return item[idName] == id
            })
            if (seleRow.length != 0) {
                return seleRow[0][name]
            }
        },

        getAllState() {
            this.$axios(this.api.collection.listOutBount, {}, 'get').then(data => {
                if (data.status) {
                    this.storeStateList = data.data
                }
            })
            this.$axios(this.api.collection.listOrderReview, {}, 'get').then(data => {
                if (data.status) {
                    this.reviewStateList = data.data
                }
            })
        },

        //列表展示
        getlistSelet(id, data, idName, name) {
            let seleRow = data.filter(item => {
                return item[idName] == id
            })
            if (seleRow.length != 0) {
                return seleRow[0][name]
            }
        },

        //边打边搜
        remoteMethod(query) {
            this.applicantLoading = true;
            this.$axios(this.api.collection.searchUserByLike, {username: query}, 'get').then(data => {
                if (data.status) {
                    this.applicantList = data.data
                    this.applicantLoading = false;
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.bg-white {
    overflow-y: auto !important;
}

.searchForm {
    .el-form-item--small.el-form-item {
        display: inline-block;
        width: 24.5%;
    }

    .dateBox2.el-form-item--small.el-form-item {
        display: inline-block;
        width: 50%;
    }

    .dateBox3.el-form-item--small.el-form-item {
        display: inline-block;
        width: 100%;
    }
}
</style>